/*
* HSCore
* @version: 2.0.0 (Mon, 25 Nov 2019)
* @requires: jQuery v3.0 or later
* @author: HtmlStream
* @event-namespace: .HSCore
* @license: Htmlstream Libraries (https://htmlstream.com/licenses)
* Copyright 2020 Htmlstream
*/
'use strict';

require("./bootstrap.bundle.min")

const HSMegaMenu = require("./hs-mega-menu.min")
window.HSMegaMenu = HSMegaMenu

// require("./jquery.cubeportfolio.min")
// require("./hs-sticky-block.min")
// require("./hs.cubeportfolio")

// require("./hs.slick-carousel.js")

$.extend({
  HSCore: {
    init: function () {
      // $(document).ready(function () {
      document.addEventListener('turbolinks:load', () => {
        // // Botostrap Tootltips
        $('[data-toggle="tooltip"]').tooltip();
        //
        // // Bootstrap Popovers
        $('[data-toggle="popover"]').popover()
        console.log('INIT Core')
        var megaMenu = new HSMegaMenu($('.js-mega-menu'), {
          desktop: {
            position: 'left'
          }
        }).init()

      })
    },

    components: {}
  }
});

$.HSCore.init()


// document.addEventListener('turbolinks:load', () => {
//   $('.cbp').each(function () {
//     var cbp = $.HSCore.components.HSCubeportfolio.init($(this))
//   })
//
//   // initialization of sticky blocks
//   $('.js-sticky-block').each(function () {
//     var stickyBlock = new HSStickyBlock($(this)).init()
//   })
// })
