// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

const $ = require("../vendor/jquery.min")
window.$ = $
window.jQuery = $

// require("../vendor/bootstrap.bundle.min")
// require("../vendor/dzsparallaxer")
require("../vendor/jquery.fancybox")

require("../vendor/hs.core")

const toastr = require("toastr")

// require("../vendor/select2.full.min")
// require("../vendor/hs.select2")
//

// require("../vendor/hs.slick-carousel.js")

require("../vendor/slick")

const Selectr = require('mobius1-selectr/dist/selectr.min')

const Typed = require('../vendor/typed.min')

import AOS from 'aos'

document.addEventListener('turbolinks:load', () => {

  AOS.init()

  if ($('.js-text-animation').length) {
    var typed = new Typed(".js-text-animation", {
      strings: $('.js-text-animation').data('text').split('|'),
      typeSpeed: 90,
      loop: true,
      backSpeed: 30,
      backDelay: 2500
    })
  }

  if (document.getElementById('mySelect')) {
    const selector = new Selectr('#mySelect', {
      placeholder: 'Поиск услуг',
      defaultSelected: false,
      messages: {
        noResults: 'Нет результатов'
      }
    })
    selector.on('selectr.select', (option) => {
      console.log('option', option)
      // location.replace(`/services/${option.getAttribute('value')}`)
      window.location.href = `/services/${option.getAttribute('value')}`
    })
  }

  if ($('.js-slick-carousel').length) {
    const options = JSON.parse($('.js-slick-carousel').attr('data-hs-slick-carousel-options'))
    $('.js-slick-carousel').slick(options)
  }

  // $(':radio').change(function() {
  //   console.log('New star rating: ' + this.value)
  // })
  if (document.getElementById('yashare2')) {
    Ya.share2('yashare2')
  }

  $("input[name='review[type]']").change((e) => {
    if (e.target.value == 'diagnostics') {
      $('.type-diagnostics').removeClass('d-none')
      $('.type-correction').addClass('d-none')
    } else if (e.target.value == 'correction') {
      $('.type-diagnostics').addClass('d-none')
      $('.type-correction').removeClass('d-none')
    }
  })

})

window.addReview = async (form) => {
  try {
    console.log('addReview', form)
    $.ajax({
      method: 'POST',
      url: '/api/reviews.json',
      data: $(form).serialize(),
      success: (response) => {
        console.log('response', response)
        $('#addReviewModal').modal('hide')
        toastr.success('Спасибо! Отзыв добавится после проверки модератором', 'Отзыв добавлен')
      },
      error: (response) => {
        console.error('response error', response)
      }
    })
  } catch (e) {
    console.error(`${e.name}: ${e.message}`)
  }
}

window.showReview = async (link) => {
  try {
    const review = JSON.parse($(link).attr('review'))
    $('#review-avatar').attr('src', review.employee_avatar || '')
    $('#review-name').text(review.employee_name || '')
    $('#review-rating').empty()
    for (var i = 0; i < review.stars; i++) {
      $('#review-rating').append('<li class="list-inline-item mx-0"><i class="fas fa-star"></i></li>')
    }

    if (review.type == 'correction') {
      $('#review-advantages').text(review.advantages || '')
      $('#review-disadvantages').text(review.disadvantages || '')
      $('#review-duration').text(review.duration || '')
      $('#review-dynamics').text(review.dynamics || '')
      $('.modal-review-diagnostics').addClass('d-none')
      $('.modal-review-correction').removeClass('d-none')
    } else if (review.type == 'diagnostics') {
      $('#review-reason').text(review.reason || '')
      $('#review-approach').text(review.approach || '')
      $('#review-competence').text(review.competence || '')
      $('#review-availability').text(review.availability || '')
      $('#review-course').text(review.course || '')
      $('.modal-review-diagnostics').removeClass('d-none')
      $('.modal-review-correction').addClass('d-none')
    }

    $('#review-text').text(review.text || '')

    $('#review-photo-link').attr('href', '')
    $('#review-photo').attr('src', '')
    $('#review-photo').attr('src', review.photo.large.url || '')
    $('#review-photo-link').attr('href', review.photo.url)

    $('#reviewModal').modal('show')
  } catch (e) {
    console.error(`${e.name}: ${e.message}`)
  }
}

window.sendFeedback = async (form) => {
  try {
    console.log('sendFeedback', form)
    const formData = new FormData()
    formData.append('feedback[name]', $('#firstName').val())
    formData.append('feedback[user_id]', $('#userId').val())
    formData.append('feedback[type]', $('#type').val())
    formData.append('feedback[surname]', $('#lastName').val())
    formData.append('feedback[email]', $('#emailAddress').val())
    formData.append('feedback[phone]', $('#phone').val())
    formData.append('feedback[message]', $('#message').val())
    if ($('#inputGroupFile03').prop('files')) {
      formData.append('feedback[file]', $('#inputGroupFile03').prop('files')[0])
    }
    const request = new XMLHttpRequest()

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 201) {
          if ($('#enrollCourseModal').length) {
            $('#enrollCourseModal').modal('hide')
            toastr.success('Спасибо! Мы свяжемся с Вами в ближайшее время', 'Заявка на курс отправлена')
          } else if ($('#applyJob').length) {
            toastr.success('Спасибо! Мы свяжемся с Вами в ближайшее время', 'Заявка на вакансию отправлена')
          } else {
            toastr.success('Спасибо! Мы свяжемся с Вами в ближайшее время', 'Сообщение отправлено')
          }
        }
      }
    }

    request.open("POST", "/api/feedbacks.json")
    request.send(formData)
  } catch (e) {
    toastr.error(`${e.name}: ${e.message}`)
  }
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
